import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";

// core components

function SobreNos() {
  return (
    <>
      <div className="section-aboutus text-center" id="sobre-nos">
        <Container>
          <h2 className="title-products">Sobre Nós</h2>
          <p className="description-products">
          A ZHERO é uma spinoff que nasceu da Fourge, com objetivo de 
          simplificar a relação entre pessoas, dados e tecnologia. 
          Apoiamos negócios a se reinventarem utilizando tecnologia 
          como base e alavancando o valor dos dados e o protagonismo 
          das pessoas.
          </p>
          <div className="team">
            <Row>
              <Col md="12">
                <div className="team-player">
                  <h4 className="title-products">Nosso Propósito</h4>
                  <hr className="rounded"></hr>
                  <p className="description-products">
                    Nascemos para transformar negócios, simplificando a 
                    relação das pessoas com dados e tecnologia.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

export default SobreNos;

import React from "react";

// reactstrap components
import {
  // Button,
  // Input,
  // InputGroupAddon,
  // InputGroupText,
  // InputGroup,
  Container,
  Row,
  Col,
  // Card,
  // CardHeader,
  // CardBody,
  // NavItem,
  // NavLink,
  // Nav,
  // TabContent,
  // TabPane,
} from "reactstrap";

// core components

// #262626

function Servicos() {
  // const [firstFocus, setFirstFocus] = React.useState(false);
  // const [lastFocus, setLastFocus] = React.useState(false);
  return (
    <>
      <div className="section section-about-us" id="servicos">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="10" xl="6">
              <h2 className="title">Serviços</h2>
              <p className="description">
                Escolha o melhor modelo de consultoria para sua empresa.
              </p>
            </Col>
          </Row>
          <div className="separator separator-primary"></div>
          <div className="section-story-overview">
            <Row>
              <Col md="6">
                <div className="circle">
                  <img
                    alt="..."
                    className="product-icon"
                    src={require("assets/img/dna.png").default}
                  ></img>
                </div>
                <h5 className="servico-titulo">Cultura Analítica para Maturidade de Dados</h5>
                <hr className="rounded"></hr>
                <p className="servico-descricao">
                  Auxiliamos empresas de todos os tamanhos e segmentos a 
                  entenderem a importância da utilização dos dados que geram 
                  guiando-as por um trajeto de maturidade.
                </p>
                <p className="servico-descricao">
                  Nossa metodologia consiste em apresentar ao cliente todas 
                  as possibilidades de tecnologias de mercado, voltadas a dados, 
                  que se encaixem da melhor forma para o momento da sua organização.
                </p>
              </Col>
              <Col md="6">
                <div className="circle">
                  <img
                    alt="..."
                    className="product-icon"
                    src={require("assets/img/fingerprint.png").default}
                  ></img>
                </div>
                <h5 className="servico-titulo">Transformação Digital</h5>
                <hr className="rounded"></hr>
                <p className="servico-descricao">
                  Ajudamos negócios a construirem um caminho consciente 
                  de adoção de tecnologias amadurecendo o time e o negócio 
                  quanto ao olhar para dados e tecnologia.
                </p>
                <p className="servico-descricao">
                  Nosso time de estrategistas mostra como conectar processos a 
                  diferentes tecnologias com significado, impacto e simplicidade. 
                  Cada passo dado precisa colocar o negócio em um novo patamar de 
                  maturidade e ter impacto real sobre a experiência dos usuários.
                </p>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      
    </>
  );
}

export default Servicos;

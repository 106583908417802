import React from "react";

// reactstrap components
import { 
  // Button, 
  Container, 
  Row, 
  Col,
  Form, 
  // UncontrolledTooltip, 
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";
import InputMask from 'react-input-mask';

import { ToastContainer, toast } from 'react-toastify';
import emailjs from "emailjs-com";

import 'react-toastify/dist/ReactToastify.css';

// core components

function Download() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);

  const sucessToast = () => toast.success('🚀 Obrigado pelo contato! Vamos te chamar para bater um papo logo');
  const errorToast = () => toast.error('😕 Tivemos algum problema no envio... Por favor, tente mais tarde');
  const warningToast = () => toast.warning('😅 Opa! Por favor, preencha todos os campos do formulário');

  function sendEmail(e) {
    e.preventDefault();

    const formTarget = e.target
    const form = document.querySelector('form')
    const formEl = document.querySelector('form').elements;

    var name = formEl["from_name"].value
    var email = formEl["reply_to"].value
    var phone = formEl["phone"].value
    var message = formEl["message"].value

    if (name !== '' && email !== '' && phone !== '' && message !== '') {
      emailjs.sendForm('service_gmail', 'website_contact', formTarget, 'user_NN8Olhsgd70QoTFhbuDHf')
        .then((result) => {
          sucessToast();
          form.reset();
        }, (error) => {
          errorToast();
        });
    } else {
      warningToast();
    }
  }

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div
        className="section section-download"
        data-background-color="black"
        id="download-section"
      >
        <Container>
          <Row className="justify-content-md-center">
            <Col className="text-center" lg="8" md="12">
              <h3 className="title">Quer marcar uma conversa com a gente?</h3>

              <p className="description">Deixe suas informações que entraremos em contato.</p>
                
              <Form onSubmit={sendEmail}>
                <Col className="text-center ml-auto mr-auto" lg="6" md="8">
                  <InputGroup
                    className={
                      "input-lg" + (firstFocus ? " input-group-focus" : "")
                    }
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="now-ui-icons users_circle-08"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Nome"
                      type="text"
                      name="from_name"
                      autoComplete="off"
                      onFocus={() => setFirstFocus(true)}
                      onBlur={() => setFirstFocus(false)}
                      ></Input>
                  </InputGroup>
                  <InputGroup
                    className={
                      "input-lg" + (lastFocus ? " input-group-focus" : "")
                    }
                    >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="now-ui-icons tech_mobile"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Celular"
                      type="tel"
                      mask="(99) 9 9999-9999"
                      maskChar=" "
                      tag={InputMask}
                      name="phone"
                      autoComplete="off"
                      onFocus={() => setLastFocus(true)}
                      onBlur={() => setLastFocus(false)}
                      ></Input>
                  </InputGroup>
                  <InputGroup
                    className={
                      "input-lg" + (lastFocus ? " input-group-focus" : "")
                    }
                    >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="now-ui-icons ui-1_email-85"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="E-mail"
                      type="email"
                      name="reply_to"
                      autoComplete="off"
                      onFocus={() => setLastFocus(true)}
                      onBlur={() => setLastFocus(false)}
                      ></Input>
                  </InputGroup>
                  <div className="textarea-container">
                    <Input
                      cols="80"
                      name="message"
                      placeholder="Mensagem..."
                      rows="4"
                      type="textarea"
                      ></Input>
                  </div>
                  <div className="send-button">
                    <br />
                    <br />
                    <Input
                      type="submit"
                      value="Enviar"
                      autoComplete="off"
                      className="btn-submit"
                      // color="info"
                      size="lg"
                      ></Input>
                    {/* <Button
                      type="submit"
                      block
                      className="btn-round"
                      color="info"
                      // href="#pablo"
                      // onClick="submit"
                      onClick={(e) => e.preventDefault()}
                      size="lg"
                      >
                      Enviar Mensagem
                    </Button> */}
                  </div>
                </Col>
              </Form>
            </Col>
          </Row>
          <br></br>
          <br></br>
          <br></br>
        </Container>
      </div>
    </>
  );
}

export default Download;

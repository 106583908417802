import React from "react";

// reactstrap components
import {
  // Button,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  // UncontrolledTooltip,
} from "reactstrap";

// core components

function Produtos() {
  const [pills, setPills] = React.useState("2");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <div className="section-products" id="produtos">
        <Container>
          <h2 className="title-products text-center">Produtos</h2>
          <p className="description-products text-center">
            Veja todos os produtos que oferecemos para potencializar seu negócio!
          </p>
          <Row>
            {/* <Col className="ml-auto mr-auto" md="6"> */}
            <Col className="ml-auto mr-auto" md="10">
              <div className="nav-align-center">
                <Nav
                  className="nav-pills-info nav-pills-just-icons"
                  pills
                  role="tablist"
                >
                  <NavItem>
                    <NavLink
                      className={pills === "1" ? "active" : ""}
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        setPills("1");
                      }}
                    >
                      <img
                      alt="..."
                      className="product-icon"
                      // src={require("assets/img/home.png").default}
                      src={pills === "1" ? require("assets/img/home-white.png").default : require("assets/img/home.png").default}
                      ></img>
                      {/* <img className="now-ui-icons business_chart-bar-32"></i> */}
                      {/* <i className="now-ui-icons business_chart-bar-32"></i> */}
                    </NavLink>
                    <p className="produto-icone-titulo">Dashboards de BI</p>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={pills === "2" ? "active" : ""}
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        setPills("2");
                      }}
                    >
                      <img
                      alt="..."
                      className="product-icon"
                      // src={require("assets/img/home.png").default}
                      src={pills === "2" ? require("assets/img/data-processing-white.png").default : require("assets/img/data-processing.png").default}
                      ></img>
                      {/* <i className="now-ui-icons location_world"></i> */}
                    </NavLink>
                    <p className="produto-icone-titulo">Arquitetura de Dados</p>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={pills === "3" ? "active" : ""}
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        setPills("3");
                      }}
                    >
                      <img
                      alt="..."
                      className="product-icon"
                      // src={require("assets/img/home.png").default}
                      src={pills === "3" ? require("assets/img/ai-brain-white.png").default : require("assets/img/ai-brain.png").default}
                      ></img>
                      {/* <i className="now-ui-icons sport_user-run"></i> */}
                    </NavLink>
                    <p className="produto-icone-titulo">Ciência de Dados / IA</p>
                  </NavItem>
                </Nav>
              </div>
            </Col>
            <TabContent className="gallery" activeTab={"pills" + pills}>
              <TabPane tabId="pills1">
                <Col className="ml-auto mr-auto" md="10">
                  <Row className="collections">
                    <Col md="6">
                      <img
                        alt="..."
                        className="img-raised"
                        src={require("assets/img/dashboard.png").default}
                      ></img>
                    </Col>
                    <Col md="6">
                      <h5 className="produto-titulo">Dashboards de BI</h5>
                      <p className="produto-descricao">
                        Construímos dashboards que buscam validar 
                        as regras de negócio e dar mais inteligência 
                        para seus processos por meio de uma imersão 
                        nos seus dados.
                      </p>
                      {/* <img
                        alt="..."
                        className="img-raised"
                        src={require("assets/img/bg8.jpg").default}
                      ></img> */}
                    </Col>
                  </Row>
                </Col>
              </TabPane>
              <TabPane tabId="pills2">
                <Col className="ml-auto mr-auto" md="10">
                  <Row className="collections">
                    <Col md="6">
                      <img
                        alt="..."
                        className="img-raised"
                        src={require("assets/img/server.png").default}
                      ></img>
                    </Col>
                    <Col md="6">
                      <h5 className="produto-titulo">Arquitetura de Dados</h5>
                      <p className="produto-descricao">
                        Construímos um ambiente de armazenamento centralizado 
                        e governado para todos os dados do seu negócio, facilitando 
                        empregá-los em dashboards de BI ou projetos de ciência de 
                        dados e inteligência artificial.
                      </p>
                    </Col>
                  </Row>
                </Col>
              </TabPane>
              <TabPane tabId="pills3">
                <Col className="ml-auto mr-auto" md="10">
                  <Row className="collections">
                    <Col md="6">
                      <img
                        alt="..."
                        className="img-raised"
                        src={require("assets/img/model.png").default}
                      ></img>
                    </Col>
                    <Col md="6">
                      <h5 className="produto-titulo">Ciência de Dados / IA</h5>
                      <p className="produto-descricao">
                        Desenvolvemos e implementamos modelos estatísticos e 
                        algoritmos de aprendizado de máquina para dar inteligência 
                        aos processos do seu negócio.
                      </p>
                    </Col>
                  </Row>
                </Col>
              </TabPane>
            </TabContent>
          </Row>
        </Container>
      </div>        
    </>
  );
}

export default Produtos;

import React from "react";
// import { Link } from "react-router-dom";
// reactstrap components
import {
  // Button,
  Collapse,
  // DropdownToggle,
  // DropdownMenu,
  // DropdownItem,
  // UncontrolledDropdown,
  // NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  // UncontrolledTooltip,
} from "reactstrap";

function IndexNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 399 ||
        document.body.scrollTop > 399
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 400 ||
        document.body.scrollTop < 400
      ) {
        setNavbarColor("navbar-transparent");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={"fixed-top " + navbarColor} expand="lg" color="info">
        <Container>
          <div className="navbar-translate">
            
            <img
              alt="Zhero Logo"
              src={require("assets/img/logo-small.png").default}
              className="brand-logo"
              
              onClick={(e) => {
                e.preventDefault();
                document
                  .getElementById("index")
                  .scrollIntoView();
              }}
            >
            </img>
            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse
            className="justify-content-end"
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar>
              <NavItem>
                <NavLink
                  href="#servicos"
                  onClick={(e) => {
                    e.preventDefault();
                    document
                      .getElementById("servicos")
                      .scrollIntoView();
                  }}
                >
                  {/* <i className="now-ui-icons arrows-1_cloud-download-93"></i> */}
                  <p>Serviços</p>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#produtos"
                  onClick={(e) => {
                    e.preventDefault();
                    document
                      .getElementById("produtos")
                      .scrollIntoView();
                  }}
                >
                  <p>Produtos</p>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#sobre-nos"
                  onClick={(e) => {
                    e.preventDefault();
                    document
                      .getElementById("sobre-nos")
                      .scrollIntoView();
                  }}
                >
                  <p>Sobre Nós</p>
                </NavLink>
              </NavItem>
              {/* <NavItem>
                <NavLink
                  href="#time"
                  onClick={(e) => {
                    e.preventDefault();
                    
                    document
                      .getElementById("time")
                      .scrollIntoView();
                  }}
                >
                  <p>Time</p>
                </NavLink>
              </NavItem> */}
              {/* <NavItem>
                <NavLink
                  href="#cases"
                  onClick={(e) => {
                    e.preventDefault();
                    
                    document
                      .getElementById("carousel")
                      .scrollIntoView();
                  }}
                >
                  <p>Cases</p>
                </NavLink>
              </NavItem> */}
              <NavItem>
                <NavLink
                  href="#contato"
                  onClick={(e) => {
                    e.preventDefault();
                    
                    document
                      .getElementById("download-section")
                      .scrollIntoView();
                  }}
                >
                  <p>Contato</p>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="https://wa.me/5551992539381"
                  target="_blank"
                  id="whatsapp-tooltip"
                >
                  {/* <i className="fab fa-instagram"></i> */}
                  <i class="fab fa-whatsapp"></i>
                  <p className="d-lg-none d-xl-none">Whatsapp</p>
                </NavLink>
                {/* <UncontrolledTooltip target="#instagram-tooltip">
                  Follow us on WhatsApp
                </UncontrolledTooltip> */}
              </NavItem>
              <NavItem>
                <NavLink
                  href="https://www.linkedin.com/company/zh-ero/"
                  target="_blank"
                  id="linkedin-tooltip"
                >
                  {/* <i className="fab fa-instagram"></i> */}
                  <i class="fab fa-linkedin"></i>
                  <p className="d-lg-none d-xl-none">LinkedIn</p>
                </NavLink>
                {/* <UncontrolledTooltip target="#instagram-tooltip">
                  Follow us on LinkedIn
                </UncontrolledTooltip> */}
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default IndexNavbar;
